<template>
  <v-container
      dense
      no-gutters
      class="d-flex align-center py-1 pr-1 messagerie-list--mini--item rounded"
      :class="rowClasses"
      v-ripple
      fluid
  >
    <v-col cols="1.5" class="text-small px-1 d-flex flex-nowrap">
      <v-icon class="mr-1" color="error" small v-if="fiche.fiche.impact.value.id === 4">mdi-alert</v-icon>
      <v-chip
          small
          :color="fiche.fiche.impact.value.id === 4 ? 'error' : 'primary'"
          label
          dark
          class="text-small"
          @click.stop="
            copyToClipboard(makeUrl(fiche), $nSuccess($t('actions.copied')))
          "
      >{{ fiche.fiche.ticket }}
      <v-icon v-if="fiche.fiche.phone_be_contacted"
            @click.stop="copyToClipboard(fiche.fiche.phone, $nSuccess($t('actions.phone-copied')))"
            color="white"
            small
            right
            :title="($t('actions.phone-copied'))"
            >mdi-phone-incoming-outline
          </v-icon>
      </v-chip>
    </v-col>
    <v-col cols="1.5" class="text-small px-1">
      <p class="pa-0 ma-0" :class="fiche.fiche.impact.value.id === 4 ? 'red--text' : ''">
      {{ fiche.fiche.url.value.name }}
      </p>
    </v-col
    >
    <v-col cols="1" class="text-small px-1">
      <span :class="fiche.fiche.impact.value.id === 4 ? 'red--text' : ''">{{
          usernameUser(fiche.fiche.userCreate)
        }}</span></v-col
    >
    <v-col cols="1" class="text-small px-1">
      <v-chip
          x-small
          :color="ColorsUtils.getTypeColor(fiche.fiche.type_demande.value.id)"
          label
          dark
          class="elevation-1 mr-4 chip-truncate"
      >
        {{ $t("messagerie.parametrages." + fiche.fiche.type_demande.text) }}
      </v-chip>
    </v-col
    >
    <v-col cols="1.5" class="text-small px-1 "  :class="fiche.fiche.impact.value.id === 4 ? 'red--text' : ''" v-html="fiche.fiche.objet"></v-col>
    <v-col cols="2" class="text-small px-1 last-message"  :class="fiche.fiche.impact.value.id === 4 ? 'red--text' : ''">
      <span>
        {{ usernameUser(lastMessage(fiche.fiche).user) }}
      </span>
      <span >
        {{ dateMessageChat(lastMessage(fiche.fiche).time, "DD MMM YYYY HH[:]mm") }}
      </span>
      <p class="format-html" v-html="removeImgMsg(fiche.fiche.last_message.content)">

      </p>
    </v-col>
    <v-col cols="2.5" class="text-small px-1"  :class="fiche.fiche.impact.value.id === 4 ? 'red--text' : ''">{{
        getModuleName(fiche.fiche.module, fiche.fiche.sous_module, modules).module.name
      }}<span v-if="fiche.fiche.sous_module">
        -
        {{
          getModuleName(fiche.fiche.module, fiche.fiche.sous_module, modules).sous_module.name
        }}</span
      ></v-col
    >
    <v-col cols="1" class="text-small px-1">
      <v-chip
          x-small
          :color="getColorEtat(fiche.fiche.etat.value.id)"
          label
          dark
          class="font-weight-bold elevation-1 etiquette--fiche"
      >
        {{ $t("messagerie.parametrages." + fiche.fiche.etat.text) }}
      </v-chip>
    </v-col>
    <v-col cols="1" class="text-small px-1">
      <v-chip
          x-small
          :color="getImpactColor"
          label
          dark
          class="elevation-1 mr-4 chip-truncate"
      >
        {{ $t("messagerie.parametrages." + fiche.fiche.impact.text) }}
      </v-chip>
    </v-col>
    <v-col cols="1" class="text-small px-1">
      <v-chip
          x-small
          :color="getPrioriteColor"
          label
          dark
          class="elevation-1 mr-4 chip-truncate"
      >
        {{ $t("messagerie.parametrages." + fiche.fiche.ordre_priorite.text) }}
      </v-chip>
    </v-col>

    <div cols="" class="text-small ">
      <v-icon class="mr-1"
          v-if="fiche.fiche.appel_client && fiche.fiche.appel_client.length > 0"
          small
          color="green"
          :title="fiche.fiche.appel_client[fiche.fiche.appel_client.length - 1].user"
      >
        mdi-phone
      </v-icon>
      <v-icon class="mr-1"
      v-if="fiche.fiche.appel_client && fiche.fiche.appel_client.length == 0"
      small
      color="#ccc"
      title="Pas d'appel client"
      >
        mdi-phone-hangup
      </v-icon>
      <v-icon class="mr-1"
        v-if="fiche.fiche.suivi"
        small
        color="#F0F"
        title="Fiche suivie"
      >
        mdi-bookmark-check
      </v-icon>

      <v-icon  v-else-if="!fiche.fiche.suivi"
        small
        color="#ccc"
        title="Fiche non suivie"
        class="mr-1"
      >
        mdi-bookmark-remove
      </v-icon>
      <!-- icon ouvrir dans un nouvel onglet -->
      <a :href="makeUrl(fiche)" @click.stop target="_blank" rel="noopener noreferrer">
        <v-icon  color="primary" size="15" title="Ouvrir dans un nouvel onglet">
          mdi-open-in-new
        </v-icon>
      </a>
    </div>

<!--    <v-chip-->
<!--        class="text-small"-->
<!--        label-->
<!--        :color="fiche.fiche.phone_be_contacted ? 'success' : 'red'"-->
<!--        dark-->
<!--        small-->
<!--        v-if="fiche.fiche.phone != ''"-->
<!--        @click.stop="-->
<!--        copyToClipboard(fiche.fiche.phone, $nSuccess($t('actions.copied')))-->
<!--      "-->
<!--    >{{ fiche.fiche.phone }}-->
<!--    </v-chip-->
<!--    >-->
    <menu-fiche
        :mini="true"
        :fiche="fiche"
        :etats="getMessagerieData().etats"
        :impacts="getMessagerieData().impacts"
        :ordre_priorites="getMessagerieData().ordre_priorites"
        :type_demandes="getMessagerieData().type_demandes"
        :rightsUser="getMessagerieData().rightsUser"
        :tagsPlaneteOnline="getMessagerieData().tagsPlaneteOnline"
        :users="getMessagerieData().users"
        :user="user"
        :openHover="true"
    />
  </v-container>
</template>

<script>
import ColorsUtils from "@/Utils/MessagerieColors";
import moment from "moment";
import Vuex from "vuex";

export default {
  name: "TicketCardMini",
  components: {
    Color: () => import("@/Components/Commons/UiElements/Color"),
    MenuFiche: () => import("@/Components/Views/Hotline/Ticket/TicketMenu"),
  },
  props: {
    fiche: Object,
    selected: Boolean,
    user: Object,
  },
  computed: {
    ...Vuex.mapGetters(["getMessagerieData"]),
    ...Vuex.mapState(["modules"]),
    rowClasses() {
      let classes = {};

      classes["selected"] = this.selected;
      classes["urgency-fiche-inset"] = this.fiche.fiche.impact.value.id === 4;
      classes["primary"] = this.selected;
      classes["lighten-5"] = this.selected;
      classes[this.$vuetify.theme.dark ? 'notSeenLight' : 'notSeen'] = this.userNotSeen(this.fiche) > 0 ;
      classes["new_fiche"] = this.fiche.fiche.new_fiche && !this.selected && this.userNotSeen(this.fiche) == 0;
      classes["archived"] =
          (this.fiche.fiche.archived || this.fiche.fiche.old_fiche) &&
          !this.selected;
      // Si messages internes et droits hotline
      if (this.fiche.fiche.last_message_interne?.time > this.fiche.fiche.last_message?.time &&  this.getMessagerieData().rightsUser.hotline && !this.fiche.fiche.last_message_interne.isSeen && !this.selected) {
          classes[!this.$vuetify.theme.dark ? "internal-message" : "internal-message-dark" ] = this.fiche.fiche.last_message_interne && !this.selected;
        } 

      return classes;
    },
  },
  data() {
    return {
      ColorsUtils,
      show: false,
    };
  },
  methods: {
    makeUrl(fiche = null) {
      if (fiche) {
        return "https://" + this.getDns() + "/planete-online/app/hotline?ticket=" + fiche._id
      }
    },
    /**
     * Converti un timestamp en date lisible
     * @var time le timestamp
     */
    dateMessageChat(time, format = null) {
      return moment(time * 1000).format(format);
    },
    removeImgMsg(msg) {
      return msg.replace(/<img[^>]*>/g, "");
    },
    /**
     * Permet de savoir combien de message l'user n'a pas vu
     * @var fiche la fiche
     */
    userNotSeen(fiche) {
      // let found
      // if (this.tmpNotSeen) {
      //   found = this.tmpNotSeen.find(a => a.id == fiche._id)
      // }
      //
      // if (found) {
      //   return found.amount
      // } else {
      //   let r = Math.floor(Math.random() * 5)
      //   r -= 2
      //   if (r < 0) r = 0
      //   this.tmpNotSeen.push({
      //     amount: r,
      //     id: fiche._id
      //   })
      //   return r
      // }

      if (fiche.fiche.usersSeen.length == 0) {
        return fiche.fiche.number_messages;
      } else {
        let userSeen = fiche.fiche.usersSeen.find(
            (u) => u.idUser == this.user.id
        );
        if (typeof userSeen != "undefined") {
          return userSeen.nb;
        } else {
          return fiche.fiche.number_messages;
        }
      }
    },
    /**
     * Permet de savoir quel dernier message afficher dans la liste de gauche
     * @var fiche la fiche
     */
    lastMessage(fiche) {
      if (
          typeof fiche.last_message_interne != "undefined" &&
          typeof this.getMessagerieData().rightsUser.hotline != "undefined" && 
          this.isMasterBase()
      ) {
        typeof fiche.last_message == 'undefined'
          ? (fiche.last_message = fiche.messages)
          : fiche.last_message;
        return fiche.last_message_interne.time > fiche.last_message.time
            ? fiche.last_message_interne
            : fiche.last_message;
      } else {
        return fiche.last_message;
      }
    },
    checkMessageToday(time) {
      let ymdNow = moment().format("MM-DD-YYYY");
      let ymdMsg = moment(time * 1000).format("MM-DD-YYYY");
      if (ymdNow == ymdMsg) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Concatène le nom de l'user
     * @var userId Id de l'user
     */
    usernameUser(user) {
      return user.firstname + " " + user.lastname.toUpperCase();
    },
    /**
     * Permet d'avoir le nombre d'autres utilisateurs dans la fiche
     * @var fiche La fiche
     */
    getNbUserFiches(fiche) {
      return fiche.fiche.users
          .filter((u) => {
            if (u.id != this.user.id) {
              return u;
            }
            if (u.id != fiche.fiche.userCreate.id) {
              return u;
            }
          })
          .sort(this.compareUsers).length;
    },
    minifyMessage(message) {
      return message.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.last-message p {
  margin: 0 !important;
  overflow: hidden;
  height: 1.2em;
}

.messagerie-list--mini--item {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  transition: all 0.2s ease-in-out;
  

  &:hover::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }

  &.notSeen {
    font-weight: bold;
    color: #151515;
    border: #cccccc 1px solid;
    box-shadow: 0 0 10px 3px #ccc inset;
  }
  // ----------------- DARK MODE ----------------- //
  &.notSeenLight {
    font-weight: bold;
    color: #e9e9e9;
    border: #cccccc 1px solid;
    box-shadow: 0 0 10px 3px #ccc inset;
    background-color: rgb(95, 95, 95);
  }

  &.internal-message {
    font-weight: bold;
    color: #000;
    border: #009688 1px solid ;
    box-shadow: 0 0 10px 3px #ccc inset;
  }
  &.internal-message-dark {
    font-weight: bold;
    color: #fff;
    border: #009688 1px solid;
    box-shadow: 0 0 10px 3px #009688 inset;
  }

  &.selected {
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      width: 4px;
      background-color: var(--v-primary-base) !important;
      pointer-events: none;
    }
  }

  &.new_fiche {
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      width: 4px;
      background-color: var(--v-warning-base) !important;
      pointer-events: none;
      
    }
  }
  
  &.archived {
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      bottom: 0;
      top: 0;
      width: 4px;
      background-color: var(--v-info-base) !important;
      pointer-events: none;
    }
  }
}

.chip-truncate {
  max-width: calc(100% - 16px);

  span {
    line-height: 17px;
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
}

.urgency-fiche-inset {
  box-shadow: 0 0 15px 3px var(--v-error-base) inset;
  -webkit-box-shadow: 0 0 4px 1px var(--v-error-base) inset;
  -moz-box-shadow: 0 0 15px 3px var(--v-error-base) inset;
}
</style>
